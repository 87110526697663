import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
// import { Redirect, Route } from "react-router";


function App() {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const history = useHistory();
    //console.log(history)

    function submitHandler(event) {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ 
                "Username": username,
                "Password": password
            })
        };

        fetch(process.env.REACT_APP_PROD_IP + '/login', requestOptions).then((response) => {
            return response.json();
            //console.log(response);
        }).then((result) => {
            if (result !== '' && result !== 'error') {
                localStorage.setItem('mainkey', result)
                history.push({
                    pathname: '/',
                    state: { detail: "sdf" }
                })
                window.location.reload()
            }
        });
    }

    return (
    <div className="container mx-auto h-full flex justify-center items-center">
        <div className="w-1/3">
            <h1 className="font-hairline mb-6 text-center">Login to Muffin</h1>
            <div className="border-teal p-8 border-t-12 bg-white mb-6 rounded-lg shadow-lg">
                <form onSubmit={submitHandler}>
                    <div className="mb-4">
                        <label className="font-bold text-grey-darker block mb-2">Username or Email</label>
                        <input value={username} onChange={e => setUsername(e.target.value)} type="text" className="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow" placeholder="Your Username" />
                    </div>

                    <div className="mb-4">
                        <label className="font-bold text-grey-darker block mb-2">Password</label>
                        <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow" placeholder="Your Password" />
                    </div>

                    <div className="flex items-center justify-between">
                        <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Login</button>
                        <a className="no-underline inline-block align-baseline font-bold text-sm text-blue hover:text-blue-dark float-right" href="/#">Forgot Password?</a>
                    </div>
                </form>
            </div>
            <div className="text-center">
                <p className="text-grey-dark text-sm">Don't have an account? <a href="/#" className="no-underline text-blue font-bold">Create an Account</a>.</p>
            </div>
        </div>
    </div>
    )
}

export default App