import React, { useState, useEffect } from 'react';
import Moment from 'moment'
import Loader from '../Loader'
//import { Link, BrowserRouter as Router, Route } from 'react-router-dom';

import Swal from 'sweetalert2'

// import { format } from "date-fns";

import { Paper } from "@material-ui/core";
// import Select from '@material-ui/core/Select';
// import MenuItem from "@material-ui/core/MenuItem";
// import Input from "@material-ui/core/Input";
import { EditingState } from '@devexpress/dx-react-grid';
import { useHistory } from "react-router-dom";

import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';

function App() {
    const history = useHistory();

    const getRowId = row => row.ID;

    const [rows, setRows] = useState([])
    const columns = [
        { name: 'Name', title: 'Name' },
        { name: 'Ticker', title: 'Ticker' },
        { name: 'MyPrice', title: 'MyPrice' },
        { name: 'CurrentPrice', title: 'CurrentPrice' },
        { name: 'Category', title: 'Category' },
        { name: 'Subcategory', title: 'Subcategory' },
        { name: 'TotalSharesOnMarket', title: 'TotalSharesOnMarket' },
        { name: 'RealTotalSharesOnMarket', title: 'RealTotalSharesOnMarket' },
        { name: 'MarketCap', title: 'MarketCap' },
        { name: 'Coeff', title: 'Coeff' },
        { name: 'QuotationLevel', title: 'QuotationLevel' },
        { name: 'Rate', title: 'Rate' },
        { name: 'Profitprice', title: 'Profitprice' },
        { name: 'Profit2020year', title: 'Profit2020year' },
        { name: 'Profit2019year', title: 'Profit2019year' },
        { name: 'Profit2018year', title: 'Profit2018year' },
        { name: 'Profit2017year', title: 'Profit2017year' },
        { name: 'Profit2016year', title: 'Profit2016year' },
        { name: 'Profit2015year', title: 'Profit2015year' },
        { name: 'Profit2014year', title: 'Profit2014year' },
        { name: 'Profit2013year', title: 'Profit2013year' },
        { name: 'Profit2012year', title: 'Profit2012year' },
        { name: 'Profit2011year', title: 'Profit2011year' },
        { name: 'Profit2010year', title: 'Profit2010year' },
        { name: 'ProfitSum3LastYears', title: 'ProfitSum3LastYears' },
        { name: 'PERatio', title: 'PERatio' },
        { name: 'FinalRate', title: 'FinalRate' },
        { name: 'DateLastUpdate', title: 'DateLastUpdate' },
    ];

    const [tableColumnExtensions] = useState([
        { columnName: 'Name', width: 250 },
        { columnName: 'Ticker', width: 100 },
        { columnName: 'MyPrice', width: 100 },
        { columnName: 'CurrentPrice', width: 100 },
        { columnName: 'Category', width: 270 },
        { columnName: 'Subcategory', width: 400 },
        { columnName: 'Coeff', width: 130 },
        { columnName: 'QuotationLevel', width: 50 },
        { columnName: 'Rate', width: 50 },
        { columnName: 'Profitprice', width: 100 },
        { columnName: 'Profit2020year', width: 100 },
        { columnName: 'Profit2019year', width: 100 },
        { columnName: 'Profit2018year', width: 100 },
        { columnName: 'Profit2017year', width: 100 },
        { columnName: 'Profit2016year', width: 100 },
        { columnName: 'Profit2015year', width: 100 },
        { columnName: 'Profit2014year', width: 100 },
        { columnName: 'Profit2013year', width: 100 },
        { columnName: 'Profit2012year', width: 100 },
        { columnName: 'Profit2011year', width: 100 },
        { columnName: 'Profit2010year', width: 100 },
        { columnName: 'ProfitSum3LastYears', width: 200 },
        { columnName: 'TotalSharesOnMarket', width: 200 },
        { columnName: 'RealTotalSharesOnMarket', width: 200 },
        { columnName: 'PERatio', width: 250 },
        { columnName: 'FinalRate', width: 250 },
        { columnName: 'MarketCap', width: 250 },
        { columnName: 'DateLastUpdate', width: 300 },
    ]);

    const [editingStateColumnExtensions] = useState([
        { columnName: 'TotalSharesOnMarket', editingEnabled: false },
        { columnName: 'MarketCap', editingEnabled: false },
        { columnName: 'Coeff', editingEnabled: false },
        { columnName: 'DateLastUpdate', editingEnabled: false },
        { columnName: 'ProfitSum3LastYears', editingEnabled: false },
        { columnName: 'PERatio', editingEnabled: false },
        { columnName: 'FinalRate', editingEnabled: false },
        { columnName: 'CurrentPrice', editingEnabled: false },
        { columnName: 'Profitprice', editingEnabled: false },
    ]);

    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        const mainkey = localStorage.getItem('mainkey')
        fetch(process.env.REACT_APP_PROD_IP + '/forecastforfunds', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        //.then(response => response.json())
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
              return response.json();
              //console.log('1');
            } else {
                //console.log('2');
                localStorage.clear()
                history.push({
                    pathname: '/login',
                    state: { detail: "sdf" },
                })
                window.location.reload()
                throw Error(response.statusText);
            }
        })
        .then(rows => {
            // setTimeout(() => {
                console.log(rows)
                setRows(rows)
                setLoading(false)
            // }, 1)
        })
    }, [history])

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////       DELETE     ////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function SweetAlert(item) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                deleteFund(item)
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        });
    }

    function deleteFund (item) {
        console.log('deleteitem', item)
        const mainkey = localStorage.getItem('mainkey')
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify({ id: item })
        };

        fetch(process.env.REACT_APP_PROD_IP + '/forecastforfunds/' + item, requestOptions).then((response) => {
            return response.json();
        })
        .then((result) => {
            // do what you want with the response here
        });
	   
        //window.location.reload(false);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////       SAVE     /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function addFund(value) {
        console.log("!!! value", value)
        setRows(rows.concat([{
            id: Date.now(),
            amount: value[0].amount,
            category: value[0].category,
            source: value[0].source,
        }]))
        saveAddedFund(value)
    }

    function saveAddedFund(value) {
        const mainkey = localStorage.getItem('mainkey')
        console.log("VALUE", value)
        console.log("VALUE0", value[0])
        console.log("VALUE1", value[1])

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify({ 
                "amount": Number(value[0].amount),
                "category": value[0].category,
                "source": value[0].source,
                "datetime": Moment(value[0].datetime).format()
            })
        };
        console.log("requestOptions", requestOptions)
        //"datetime": Moment(value[0].datetime).format("MM.DD.yyyy")

        fetch(process.env.REACT_APP_PROD_IP + '/costs', requestOptions).then((response) => {
            return response.json();
        })
        .then((result) => {
        
        });

        //window.location.reload(false);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////      UPDATE    /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function changeFund(value) {
        editedFund(value)
    }

    function editedFund(value) {
        const mainkey = localStorage.getItem('mainkey')
        console.log("value", value)
        let blablabla

        Object.entries(value).map((item, i) => {
            console.log("i", i)
            console.log("item", item)
            console.log("item[1]", item[1])

            blablabla = item[1]
            return blablabla
        });

        console.log("blablabla", blablabla)
        
        let standjson = {
             "id": Number(Object.keys(value)[0])
        }

        console.log("standjson", standjson)

        const combined = { ...blablabla, ...standjson }

        if (combined.MyPrice) {
            combined.MyPrice = Number(combined.MyPrice)
            console.log("combined.MyPrice", combined.MyPrice)
        }

        if (combined.RealTotalSharesOnMarket) {
            combined.RealTotalSharesOnMarket = Number(combined.RealTotalSharesOnMarket)
            console.log("combined.RealTotalSharesOnMarket", combined.RealTotalSharesOnMarket)
        }

        if (combined.QuotationLevel) {
            combined.QuotationLevel = Number(combined.QuotationLevel)
            console.log("combined.QuotationLevel", combined.QuotationLevel)
        }

        if (combined.Rate) {
            combined.Rate = Number(combined.Rate)
            console.log("combined.Rate", combined.Rate)
        }

        if (combined.Rate) {
            combined.Rate = Number(combined.Rate)
            console.log("combined.Rate", combined.Rate)
        }
        
        if (combined.Profit2020year) {
            combined.Profit2020year = Number(combined.Profit2020year)
            console.log("Profit2020year: ", combined.Profit2020year)    
        }

        if (combined.Profit2019year) {
            combined.Profit2019year = Number(combined.Profit2019year)
            console.log("Profit2019year: ", combined.Profit2019year)    
        }

        if (combined.Profit2018year) {
            combined.Profit2018year = Number(combined.Profit2018year)
            console.log("Profit2018year: ", combined.Profit2018year)    
        }

        if (combined.Profit2017year) {
            combined.Profit2017year = Number(combined.Profit2017year)
            console.log("Profit2017year: ", combined.Profit2017year)    
        }

        if (combined.Profit2016year) {
            combined.Profit2016year = Number(combined.Profit2016year)
            console.log("Profit2016year: ", combined.Profit2016year)    
        }

        if (combined.Profit2015year) {
            combined.Profit2015year = Number(combined.Profit2015year)
            console.log("Profit2015year: ", combined.Profit2015year)    
        }

        if (combined.Profit2014year) {
            combined.Profit2014year = Number(combined.Profit2014year)
            console.log("Profit2014year: ", combined.Profit2014year)    
        }

        if (combined.Profit2013year) {
            combined.Profit2013year = Number(combined.Profit2013year)
            console.log("Profit2013year: ", combined.Profit2013year)    
        }

        if (combined.Profit2012year) {
            combined.Profit2012year = Number(combined.Profit2012year)
            console.log("Profit2012year: ", combined.Profit2012year)    
        }

        if (combined.Profit2011year) {
            combined.Profit2011year = Number(combined.Profit2011year)
            console.log("Profit2011year: ", combined.Profit2011year)    
        }

        if (combined.Profit2010year) {
            combined.Profit2010year = Number(combined.Profit2010year)
            console.log("Profit2010year: ", combined.Profit2010year)    
        }

        

        // if (combined.datetime) {
        //     combined.datetime = Moment(combined.datetime).format('')
        //     console.log("combined.datepurchase", combined.datetime)
        // }

        console.log("combined", combined)

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify(combined)
        };
        console.log("requestOptions", requestOptions)

        fetch(process.env.REACT_APP_PROD_IP + '/forecastforfunds/' + standjson.id, requestOptions).then((response) => {
            return response.json();
        }).then((result) => {
            // do what you want with the response here
        });
        
      //window.location.reload(false);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////   COMMIT CHANGES  ////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const commitChanges = ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
          const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 + Math.floor(Math.random() * Math.floor(34534345)) : 0;
          changedRows = [
            ...added.map((row, index) => ({
              id: startingAddedId + index,
              ...row,
            })),
            ...rows,
          ];
          console.log("ADDED")
          console.log("added", added)
          console.log("changedRows", changedRows)
          addFund(added)
        }
        if (changed) {
          changedRows = rows.map(row => (changed[row.ID] ? { ...row, ...changed[row.ID] } : row));
          changeFund(changed)
          console.log("changedRows", changedRows)
          console.log("changed", changed)
          console.log("CHANGED")
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            console.log("rows", rows)
            changedRows = rows.filter(row => !deletedSet.has(row.ID));
            console.log("deletedSet", deletedSet)
            console.log("changedRows", changedRows)
            SweetAlert(deleted[0])
        }
        setRows(changedRows);
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////     RENDER     /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
        {loading && <Loader />}
        {rows && rows.length ? (
        <Paper>
        <Grid
            rows={rows}
            columns={columns}
            getRowId={getRowId}
        >
        <EditingState
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}
        />
        <Table columnExtensions={tableColumnExtensions} />
        <TableHeaderRow />
        <TableEditRow />
        <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
        />
        </Grid>
        </Paper>
        ) : (
            loading ? null : <p>No Funds</p>
        )}
        </div>
    )
}

export default App
