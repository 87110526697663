import React, {Component} from 'react';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import Chartstable from './Chartstable';
// import Chartstablesorted from './Chartstablesorted';

import Moment from 'moment'

export default class LineDemo extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
            singlestats: {},
            btcusd: '',
            equity: [],
            sharesefficiencyrub: [{}],
            sharesefficiencyusd: [{}],
            bondsefficiencyrub: [{}],
            bondsefficiencyusd: [{}],

            standardoptions: {
                tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
            },
            sharesbondsbtcoptions: {
                legend: {
                    display: false,
                },
                animation: {
                    duration: 0,
                    // easing: 'easeOutBounce',
                    // animateRotate: true,
                    // animateScale: true,
                },
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
                scales: {
                  yAxes: [{
                    stacked: true,
                  }]
                },
            },
            equityoptions: {
                legend: {
                    display: false,
                },
                animation: {
                    duration: 0,
                    // easing: 'easeOutBounce',
                    // animateRotate: true,
                    // animateScale: true,
                },
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
                scales: {
                    yAxes: [{
                        id: 'EqRUB',
                        type: 'linear',
                        position: 'left',
                    }, {
                        id: 'EqUSD',
                        type: 'linear',
                        position: 'right',
                    }]
                }
            },

            fearandgreedoptions: {
                legend: {
                    display: false,
                },
                animation: {
                    duration: 0,
                    // easing: 'easeOutBounce',
                    // animateRotate: true,
                    // animateScale: true,
                },
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
                scales: {
                    yAxes: [{
                        id: 'FearAndGreed',
                        type: 'linear',
                        position: 'left',
                    }, {
                        id: 'EqUSD',
                        type: 'linear',
                        position: 'right',
                    }]
                }
            },

            fundsusdruboptions: {
                legend: {
                    display: false,
                },
                animation: {
                    duration: 0,
                    // easing: 'easeOutBounce',
                    // animateRotate: true,
                    // animateScale: true,
                },
                tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
                scales: {
                    yAxes: [{
                        id: 'FundsUSD',
                        type: 'linear',
                        position: 'left',
                    }, {
                        id: 'FundsRUB',
                        type: 'linear',
                        position: 'right',
                    }]
                }
            },
            options: {
                tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            },
            donutcurrent: {},
            donutgram: {},
            tabledatathismonth: [{}],
            donutthismonthdate: '',
            donutthismonth: {},
            tabledatalastmonth: [{}],
            donutlastmonthdate: '',
            donutlastmonth: {},
            tabledatalastlastmonth: [{}],
            donutlastlastmonthdate: '',
            donutlastlastmonth: {},
            donutoptions: {
                legend: {
                    display: false,
                },
            },
            bondssharesbtcusd: {},
            bondssharesbtcusdchanged: {},
            fundsusdrub: {},
            fundsusdrubchanged: {},
            usdDebtBtcFundsEquity: {},
            usdDebtBtcFundsEquitychanged: {},
            equitydata: {},
            equitydatachanged: {},
            fearandgreeddata: {},
            fearandgreeddatachanged: {},
            linedataa: {},
            naturalDiffDataRUB: {},
            naturalDiffDataUSD: {},
            costsPerMonth: {},
            earningsPerMonth: {},
            sharesrubanalytics: [{}],
            sharesrubcategory: [{}]
        };
    }

    render() {
        return (
            <div>
                <div><p className="text-6xl font-bold">COMMON</p></div>
                <div className="grid grid-cols-3 gap-4">
                    <div className="strvalue">
                        <div className="name">Bitcoin Price</div>
                        <div className="value">{this.state.singlestats.BTCUSD ? Math.floor(Number(this.state.singlestats.BTCUSD)) : ''}</div>
                    </div>
                    <div className="strvalue">
                        <div className="name">Days To Die</div>
                        <div className="value">{this.state.singlestats.DaysToDie ? Math.floor(Number(this.state.singlestats.DaysToDie)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Needs to Earn RUB</div>
                        <div className="value">{this.state.singlestats.NeedToEarnTillIDIERUB ? Math.floor(Number(this.state.singlestats.NeedToEarnTillIDIERUB)) : ''}</div>
                    </div>
                    <div className="strvalue">
                        <div className="name">USD Price</div>
                        <div className="value">{this.state.singlestats.USDRUB ? Math.floor(Number(this.state.singlestats.USDRUB) * 100) / 100 : ''}</div>
                    </div>
                    <div className="strvalue">
                        <div className="name">Money to Die</div>
                        <div className="value">{this.state.singlestats.PercentMoneyEarnedToDie ? Math.floor(Number(this.state.singlestats.PercentMoneyEarnedToDie) * 100) / 100 + '%' : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Needs to Earn USD</div>
                        <div className="value">{this.state.singlestats.NeedToEarnTillIDIEUSD ? Math.floor(Number(this.state.singlestats.NeedToEarnTillIDIEUSD)) : ''}</div>
                    </div>
                </div>
                <div><p className="text-6xl font-bold">COSTS</p></div>
                <div className="grid grid-cols-3 gap-4 pt-4">
                    <div className="strvalue rub">
                        <div className="name">{Moment().format('MMMM')} Spends RUB</div>
                        <div className="value">{this.state.singlestats.CurrentMonthExpencesRUB ? Math.floor(Number(this.state.singlestats.CurrentMonthExpencesRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">{Moment().subtract(1, 'months').format('MMMM')} Spends RUB</div>
                        <div className="value">{this.state.singlestats.LastMonthExpencesRUB ? Math.floor(Number(this.state.singlestats.LastMonthExpencesRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">{Moment().subtract(2, 'months').format('MMMM')} Spends RUB</div>
                        <div className="value">{this.state.singlestats.LastLastMonthExpencesRUB ? Math.floor(Number(this.state.singlestats.LastLastMonthExpencesRUB)) : ''}</div>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-4 pt-4 pb-6">
                    <div>
                        <h3 className="text-center mb-4">{Moment(this.state.donutthismonthdate).format('MMMM')} Costs</h3>
                        <div>
                            <div>
                                <Doughnut className="h-10" ref="chart" data={this.state.donutthismonth} options={this.state.donutoptions} />
                            </div>
                            <div className="costspermonthtable">
                                <Chartstable data={this.state.tabledatathismonth} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-center mb-4">Current Month Costs</h3>
                        <div>
                            <div>
                                <Doughnut ref="chart" data={this.state.donutlastmonth} options={this.state.donutoptions} />
                            </div>
                            <div className="costspermonthtable">
                                <Chartstable data={this.state.tabledatalastmonth} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-center mb-4">Current Month Costs</h3>
                        <div>
                            <div>
                                <Doughnut ref="chart" data={this.state.donutlastlastmonth} options={this.state.donutoptions} />
                            </div>
                            <div className="costspermonthtable">
                                <Chartstable data={this.state.tabledatalastlastmonth} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex mb-4">
                    <div className="w-3/4">
                        <div><Bar ref="chart" data={this.state.costsPerMonth} options={this.state.options} /></div>
                    </div>
                    <div className="w-1/4 p-4">
                        <div className="gap-4 mt-4 strvalue rub">
                            <div className="name">Avg Spends 3M RUB</div>
                            <div className="value">{this.state.singlestats.AverageSpendsForTheLast3MonthsRUB ? Math.floor(Number(this.state.singlestats.AverageSpendsForTheLast3MonthsRUB)) : ''}</div>
                        </div>
                        <div className="gap-4 mt-4 strvalue usd">
                            <div className="name">Avg Spends 3M USD</div>
                            <div className="value">{this.state.singlestats.AverageSpendsForTheLast3MonthsUSD ? Math.floor(Number(this.state.singlestats.AverageSpendsForTheLast3MonthsUSD)) : ''}</div>
                        </div>
                    </div>
                </div>
                
                <div><p className="text-6xl font-bold">EARNINGS</p></div>
                <div className="flex mb-4">
                    <div className="w-3/4">
                        <div><Bar ref="chart" data={this.state.earningsPerMonth} options={this.state.options} /></div>
                    </div>
                </div>

                <div><p className="text-6xl font-bold">INVESTMENT</p></div>
                <div className="grid grid-cols-5 gap-4 pt-4">
                    <div className="strvalue rub">
                        <div className="name">Clear Funds 7D RUB</div>
                        <div className="value">{this.state.singlestats.ClearFunds7LastDaysRUB ? Math.floor(Number(this.state.singlestats.ClearFunds7LastDaysRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Clear Funds 30D RUB</div>
                        <div className="value">{this.state.singlestats.ClearFunds30LastDaysRUB ? Math.floor(Number(this.state.singlestats.ClearFunds30LastDaysRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Clear Funds 90D RUB</div>
                        <div className="value">{this.state.singlestats.ClearFunds90LastDaysRUB ? Math.floor(Number(this.state.singlestats.ClearFunds90LastDaysRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Clear Funds 365D RUB</div>
                        <div className="value">{this.state.singlestats.ClearFunds365LastDaysRUB ? Math.floor(Number(this.state.singlestats.ClearFunds365LastDaysRUB)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Clear Funds All RUB</div>
                        <div className="value">{this.state.singlestats.ClearFundsAllTheTimeRUB ? Math.floor(Number(this.state.singlestats.ClearFundsAllTheTimeRUB)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Clear Funds 7D USD</div>
                        <div className="value">{this.state.singlestats.ClearFunds7LastDaysUSD ? Math.floor(Number(this.state.singlestats.ClearFunds7LastDaysUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Clear Funds 30D USD</div>
                        <div className="value">{this.state.singlestats.ClearFunds30LastDaysUSD ? Math.floor(Number(this.state.singlestats.ClearFunds30LastDaysUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Clear Funds 90D USD</div>
                        <div className="value">{this.state.singlestats.ClearFunds90LastDaysUSD ? Math.floor(Number(this.state.singlestats.ClearFunds90LastDaysUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Clear Funds 365D USD</div>
                        <div className="value">{this.state.singlestats.ClearFunds365LastDaysUSD ? Math.floor(Number(this.state.singlestats.ClearFunds365LastDaysUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Clear Funds All USD</div>
                        <div className="value">{this.state.singlestats.ClearFundsAllTheTimeUSD ? Math.floor(Number(this.state.singlestats.ClearFundsAllTheTimeUSD)) : ''}</div>
                    </div>
                </div>

                <div className="grid grid-cols-4 gap-4 pt-4">
                <div className="strvalue rub">
                        <div className="name">Capitalisation RUB</div>
                        <div className="value">{this.state.singlestats.CapRUB ? Math.floor(Number(this.state.singlestats.CapRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Yearly Percent RUB</div>
                        <div className="value">{this.state.singlestats.WholeTheTimeEfficiencyRUB ? Math.floor(Number(this.state.singlestats.WholeTheTimeEfficiencyRUB) * 100) / 100 + '%' : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Passive Percent RUB</div>
                        <div className="value">{this.state.singlestats.PassivePercentRUB ? Math.floor(Number(this.state.singlestats.PassivePercentRUB) * 10) / 10 + '%' : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Investment Income RUB</div>
                        <div className="value">{this.state.singlestats.CurrentPassiveIncomeToCostsPercentRUB ? Math.floor(Number(this.state.singlestats.CurrentPassiveIncomeToCostsPercentRUB)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Capitalisation USD</div>
                        <div className="value">{this.state.singlestats.CapUSD ? Math.floor(Number(this.state.singlestats.CapUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Yearly Percent USD</div>
                        <div className="value">{this.state.singlestats.WholeTheTimeEfficiencyUSD ? Math.floor(Number(this.state.singlestats.WholeTheTimeEfficiencyUSD) * 100) / 100 + '%' : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Passive Percent USD</div>
                        <div className="value">{this.state.singlestats.PassivePercentUSD ? Math.floor(Number(this.state.singlestats.PassivePercentUSD) * 10) / 10 + '%' : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Investment Income USD</div>
                        <div className="value">{this.state.singlestats.CurrentPassiveIncomeToCostsPercentUSD ? Math.floor(Number(this.state.singlestats.CurrentPassiveIncomeToCostsPercentUSD)) : ''}</div>
                    </div>
                </div>

                <div className="grid grid-cols-6 gap-4 pt-4">
                    <div className="strvalue rub">
                        <div className="name">ETF in RUB</div>
                        <div className="value">{this.state.singlestats.TotalETFRUB ? Math.floor(Number(this.state.singlestats.TotalETFRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">BTC in RUB</div>
                        <div className="value">{this.state.singlestats.CurrentBTCinRUB ? Math.floor(Number(this.state.singlestats.CurrentBTCinRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Cash RUB</div>
                        <div className="value">{this.state.singlestats.TotalBrokerCashRUB ? Math.floor(Number(this.state.singlestats.TotalBrokerCashRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Bonds RUB</div>
                        <div className="value">{this.state.singlestats.TotalBondsRUB ? Math.floor(Number(this.state.singlestats.TotalBondsRUB)) : '0'}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Shares RUB</div>
                        <div className="value">{this.state.singlestats.TotalSharesRUB ? Math.floor(Number(this.state.singlestats.TotalSharesRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">Funds RUB</div>
                        <div className="value">{this.state.singlestats.TotalFundsRUB ? Math.floor(Number(this.state.singlestats.TotalFundsRUB)) : ''}</div>
                    </div>
                    <div className="strvalue rub">
                        <div className="name">ETF in USD</div>
                        <div className="value">{this.state.singlestats.TotalETFUSD ? Math.floor(Number(this.state.singlestats.TotalETFUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">BTC in USD</div>
                        <div className="value">{this.state.singlestats.CurrentBTCinUSD ? Math.floor(Number(this.state.singlestats.CurrentBTCinUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Cash USD</div>
                        <div className="value">{this.state.singlestats.TotalBrokerCashUSD ? Math.floor(Number(this.state.singlestats.TotalBrokerCashUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Bonds USD</div>
                        <div className="value">{this.state.singlestats.TotalBondsUSD ? Math.floor(Number(this.state.singlestats.TotalBondsUSD)) : '0'}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Shares USD</div>
                        <div className="value">{this.state.singlestats.TotalSharesUSD ? Math.floor(Number(this.state.singlestats.TotalSharesUSD)) : ''}</div>
                    </div>
                    <div className="strvalue usd">
                        <div className="name">Funds USD</div>
                        <div className="value">{this.state.singlestats.TotalFundsUSD ? Math.floor(Number(this.state.singlestats.TotalFundsUSD)) : ''}</div>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4 pt-4 pb-6">




                    <div>
                        <div className="text-center">
                        <div className="inline-flex">
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-l" onClick={this.changeFearAndGreedDate.bind(this, 7)}>W</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFearAndGreedDate.bind(this, 14)}> 2W</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFearAndGreedDate.bind(this, 30)}>M</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFearAndGreedDate.bind(this, 90)}>3M</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFearAndGreedDate.bind(this, 180)}>6M</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFearAndGreedDate.bind(this, 365)}>Y</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-r" onClick={this.changeFearAndGreedDate.bind(this, 'all')}>ALL</button>
                        </div>
                        </div>
                        <Line ref="chart" data={this.state.fearandgreeddatachanged} options={this.state.fearandgreedoptions} />
                    </div>






                    <div><Line ref="chart" data={this.state.linedataa} options={this.state.standardoptions} /></div>
                    <div><Bar ref="chart" data={this.state.naturalDiffDataRUB} options={this.state.standardoptions} /></div>
                    <div><Bar ref="chart" data={this.state.naturalDiffDataUSD} options={this.state.standardoptions} /></div>
                    <div>
                        <div className="text-center">
                        <div className="inline-flex">
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-l" onClick={this.changeEquityDate.bind(this, 7)}>W</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeEquityDate.bind(this, 14)}> 2W</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeEquityDate.bind(this, 30)}>M</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeEquityDate.bind(this, 90)}>3M</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeEquityDate.bind(this, 180)}>6M</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeEquityDate.bind(this, 365)}>Y</button>
                            <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-r" onClick={this.changeEquityDate.bind(this, 'all')}>ALL</button>
                        </div>
                        </div>
                        <Line ref="chart" data={this.state.equitydatachanged} options={this.state.equityoptions} />
                    </div>
                    <div>
                        <div className="text-center">
                            <div className="inline-flex">
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-l" onClick={this.changeSharesBondsBTCDate.bind(this, 7)}>W</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeSharesBondsBTCDate.bind(this, 14)}> 2W</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeSharesBondsBTCDate.bind(this, 30)}>M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeSharesBondsBTCDate.bind(this, 90)}>3M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeSharesBondsBTCDate.bind(this, 180)}>6M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeSharesBondsBTCDate.bind(this, 365)}>Y</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-r" onClick={this.changeSharesBondsBTCDate.bind(this, 'all')}>ALL</button>
                            </div>
                        </div>
                        <Line ref="chart" data={this.state.bondssharesbtcusdchanged} options={this.state.sharesbondsbtcoptions} />
                    </div>
                    <div>
                        <div className="text-center">
                            <div className="inline-flex">
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-l" onClick={this.changeFundsDate.bind(this, 7)}>W</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFundsDate.bind(this, 14)}> 2W</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFundsDate.bind(this, 30)}>M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFundsDate.bind(this, 90)}>3M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFundsDate.bind(this, 180)}>6M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeFundsDate.bind(this, 365)}>Y</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-r" onClick={this.changeFundsDate.bind(this, 'all')}>ALL</button>
                            </div>
                        </div>
                        <Line ref="chart" data={this.state.fundsusdrubchanged} options={this.state.fundsusdruboptions} />
                    </div>
                    <div>
                        <h3 className="text-center mb-4">Current Shares / Bonds</h3>
                        <Doughnut ref="chart" data={this.state.donutcurrent} options={this.state.donutoptions} />
                    </div>
                    <div>
                        <h3 className="text-center mb-4">GRAM Shares / Bonds</h3>
                        <Doughnut ref="chart" data={this.state.donutgram} options={this.state.donutoptions} />
                    </div>
                    <div>
                        <div className="text-center">
                            <div className="inline-flex">
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-l" onClick={this.changeDebtBTCFundsEquityDate.bind(this, 7)}>W</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeDebtBTCFundsEquityDate.bind(this, 14)}> 2W</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeDebtBTCFundsEquityDate.bind(this, 30)}>M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeDebtBTCFundsEquityDate.bind(this, 90)}>3M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeDebtBTCFundsEquityDate.bind(this, 180)}>6M</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3" onClick={this.changeDebtBTCFundsEquityDate.bind(this, 365)}>Y</button>
                                <button className="bg-gray-200 text-xs hover:bg-gray-400 text-gray-800 py-1 px-3 rounded-r" onClick={this.changeDebtBTCFundsEquityDate.bind(this, 'all')}>ALL</button>
                            </div>
                        </div>
                        <Line ref="chart" data={this.state.usdDebtBtcFundsEquitychanged} options={this.state.standardoptions} />
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-4 pt-4 pb-6 tablefunds">
                    <div>
                        <Chartstable data={this.state.sharesefficiencyrub} />
                    </div>
                    <div>
                        <Chartstable data={this.state.sharesefficiencyusd} />
                    </div>
                    <div>
                        <Chartstable data={this.state.bondsefficiencyrub} />
                    </div>
                    <div>
                        <Chartstable data={this.state.bondsefficiencyusd} />
                    </div>
                </div>


                <div className="grid tablefunds w-full">
                    <Chartstable data={this.state.sharesrubcategory} />
                </div>
                <div className="grid tablefunds w-full">
                    <Chartstable data={this.state.sharesrubanalytics} />
                </div>

            </div>
        );
    }

    changeDebtBTCFundsEquityDate = (numberofdays) => {
        let alldatas = this.state.usdDebtBtcFundsEquity.labels
        let allDebtArr = this.state.usdDebtBtcFundsEquity.datasets[0].data
        let allFundsArr = this.state.usdDebtBtcFundsEquity.datasets[1].data
        let allBTCArr  = this.state.usdDebtBtcFundsEquity.datasets[2].data
        let allEquityArr  = this.state.usdDebtBtcFundsEquity.datasets[3].data

        // console.log(alldatas)
        // console.log(allDebtArr)
        // console.log(allFundsArr)
        // console.log(allBTCArr)
        // console.log(allEquityArr)

        if (numberofdays === 'all') {
            numberofdays = alldatas.length
        }

        let changedDatas = alldatas.slice(Math.max(alldatas.length - numberofdays, 0))
        let changedValsDebt = allDebtArr.slice(Math.max(allDebtArr.length - numberofdays, 0))
        let changedValsFunds = allFundsArr.slice(Math.max(allFundsArr.length - numberofdays, 0))
        let changedValsBTC = allBTCArr.slice(Math.max(allBTCArr.length - numberofdays, 0))
        let changedValsEquity = allEquityArr.slice(Math.max(allEquityArr.length - numberofdays, 0))

        // console.log(changedDatas)
        // console.log(changedValsDebt)
        // console.log(changedValsFunds)
        // console.log(changedValsBTC)
        // console.log(changedValsEquity)

        this.setState({ usdDebtBtcFundsEquitychanged: {
            labels: changedDatas,
            datasets: [
                {
                    label: 'DebtUSD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(144, 233, 240, 1)',
                    borderColor: 'rgba(144, 233, 240, 1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(144, 233, 240, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(144, 233, 240, 1)',
                    pointHoverBorderColor: 'rgba(144, 233, 240, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsDebt,
                    pointBackgroundColor: 'rgba(144, 233, 240, 1)',
                },
                {
                    label: 'FundsUSD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(237, 133, 237, 1)',
                    borderColor: 'rgba(237, 133, 237, 1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(237, 133, 237, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                    pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsFunds,
                    pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                },
                {
                    label: 'CurrentBTCinUSD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(245, 204, 115, 1)',
                    borderColor: 'rgba(245, 204, 115, 1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(245, 204, 115, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(245, 204, 115, 1)',
                    pointHoverBorderColor: 'rgba(245, 204, 115, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsBTC,
                    pointBackgroundColor: 'rgba(245, 204, 115, 1)',
                },
                {
                    label: 'EqUSD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(189, 232, 146, 1)',
                    borderColor: 'rgba(189, 232, 146, 1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(189, 232, 146, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(189, 232, 146, 1)',
                    pointHoverBorderColor: 'rgba(189, 232, 146, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsEquity,
                    pointBackgroundColor: 'rgba(189, 232, 146, 1)',
                }
            ]
        }})
    }

    changeSharesBondsBTCDate = (numberofdays) => {
        let alldatas = this.state.bondssharesbtcusd.labels
        let allSharesArr = this.state.bondssharesbtcusd.datasets[0].data
        let allBondsArr = this.state.bondssharesbtcusd.datasets[1].data
        let allBTCArr  = this.state.bondssharesbtcusd.datasets[2].data
        let allCashArr = this.state.bondssharesbtcusd.datasets[3].data
        let allETFArr = this.state.bondssharesbtcusd.datasets[4].data

        console.log("1111111111", this.state.bondssharesbtcusd)

        console.log("alldatas", alldatas)
        console.log("allSharesArr", allSharesArr)
        console.log("allBondsArr", allBondsArr)
        console.log("allBTCArr", allBTCArr)
        console.log("allCashArr", allCashArr)
        console.log("allETFArr", allETFArr)

        if (numberofdays === 'all') {
            numberofdays = alldatas.length
        }

        let changedDatas = alldatas.slice(Math.max(alldatas.length - numberofdays, 0))
        let changedValsShares = allSharesArr.slice(Math.max(allSharesArr.length - numberofdays, 0))
        let changedValsBonds = allBondsArr.slice(Math.max(allBondsArr.length - numberofdays, 0))
        let changedValsBTC = allBTCArr.slice(Math.max(allBTCArr.length - numberofdays, 0))
        let changedValsCash = allCashArr.slice(Math.max(allCashArr.length - numberofdays, 0))
        let changedValsETF = allETFArr.slice(Math.max(allETFArr.length - numberofdays, 0))

        console.log("changedDatas", changedDatas)
        console.log("changedValsShares", changedValsShares)
        console.log("changedValsBonds", changedValsBonds)
        console.log("changedValsBTC", changedValsBTC)
        console.log("changedValsCash", changedValsCash)
        console.log("changedValsETF", changedValsETF)
        

        this.setState({ bondssharesbtcusdchanged: {
            labels: changedDatas,
            datasets: [
                {
                    label: 'Shares USD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(189, 232, 146,0.4)',
                    borderColor: 'rgba(189, 232, 146,1)',
                    borderCapStyle: 'butt',
                    // borderDash: [],
                    // borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(189, 232, 146,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(189, 232, 146,1)',
                    pointHoverBorderColor: 'rgba(189, 232, 146,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsShares,
                    pointBackgroundColor: 'rgba(189, 232, 146, 1)',
                },
                {
                    label: 'Bonds USD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(245, 204, 115, 1)',
                    borderColor: 'rgba(245, 204, 115, 1)',
                    borderCapStyle: 'butt',
                    // borderDash: [],
                    // borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(245, 204, 115, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(245, 204, 115, 1)',
                    pointHoverBorderColor: 'rgba(245, 204, 115, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsBonds,
                    pointBackgroundColor: 'rgba(245, 204, 115, 1)',
                },
                {
                    label: 'BTC in USD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(237, 133, 237, 1)',
                    borderColor: 'rgba(237, 133, 237, 1)',
                    borderCapStyle: 'butt',
                    // borderDash: [],
                    // borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(237, 133, 237, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                    pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsBTC,
                    pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                },
                {
                    label: 'Cash in USD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(237, 133, 237, 1)',
                    borderColor: 'rgba(237, 133, 237, 1)',
                    borderCapStyle: 'butt',
                    // borderDash: [],
                    // borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(237, 133, 237, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                    pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsCash,
                    pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                },
                {
                    label: 'ETF in USD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(237, 133, 237, 1)',
                    borderColor: 'rgba(237, 133, 237, 1)',
                    borderCapStyle: 'butt',
                    // borderDash: [],
                    // borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(237, 133, 237, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                    pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsETF,
                    pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                }
            ]
        }})
    }

    changeFundsDate = (numberofdays) => {
        let alldatas = this.state.fundsusdrub.labels
        let allRUBFundsArr = this.state.fundsusdrub.datasets[0].data
        let allUSDFundsArr = this.state.fundsusdrub.datasets[1].data

        if (numberofdays === 'all') {
            numberofdays = alldatas.length
        }

        let changedDatas = alldatas.slice(Math.max(alldatas.length - numberofdays, 0))
        let changedValsRUB = allRUBFundsArr.slice(Math.max(allRUBFundsArr.length - numberofdays, 0))
        let changedValsUSD = allUSDFundsArr.slice(Math.max(allUSDFundsArr.length - numberofdays, 0))

        // console.log(changedDatas)
        // console.log(changedValsRUB)
        // console.log(changedValsUSD)

        this.setState({ fundsusdrubchanged: {
            labels: changedDatas,
            datasets: [
                {
                    label: 'FundsRUB',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(255, 133, 133, 1)',
                    borderColor: 'rgba(255, 133, 133, 1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(255, 133, 133, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                    pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsRUB,
                    yAxisID: 'FundsRUB',
                    pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                },
                {
                    label: 'FundsUSD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(75,192,192,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsUSD,
                    yAxisID: 'FundsUSD',
                    pointBackgroundColor: 'rgba(75,192,192,1)',
                }
            ]
        }})
    }

    changeFearAndGreedDate = (numberofdays) => {
        let alldatas = this.state.fearandgreeddata.labels
        let allvalues = this.state.fearandgreeddata.datasets[0].data

        if (numberofdays === 'all') {
            numberofdays = alldatas.length
        }

        console.log("alldatas", alldatas)
        console.log("allvalues", allvalues)

        let changedDatas = alldatas.slice(Math.max(alldatas.length - numberofdays, 0))
        let changedallvalues = allvalues.slice(Math.max(allvalues.length - numberofdays, 0))

        this.setState({ fearandgreeddatachanged: {
            labels: changedDatas,
            datasets: [
                {
                    label: 'FearAndGreed',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(255, 133, 133, 0.4)',
                    borderColor: 'rgba(255, 133, 133, 1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(255, 133, 133, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                    pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedallvalues,
                    yAxisID: 'FearAndGreed',
                    pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                }
            ]
        }})
    }

    changeEquityDate = (numberofdays) => {
        let alldatas = this.state.equitydata.labels
        let allRUBFundsArr = this.state.equitydata.datasets[0].data
        let allUSDFundsArr = this.state.equitydata.datasets[1].data

        if (numberofdays === 'all') {
            numberofdays = alldatas.length
        }

        let changedDatas = alldatas.slice(Math.max(alldatas.length - numberofdays, 0))
        let changedValsRUB = allRUBFundsArr.slice(Math.max(allRUBFundsArr.length - numberofdays, 0))
        let changedValsUSD = allUSDFundsArr.slice(Math.max(allUSDFundsArr.length - numberofdays, 0))

        this.setState({ equitydatachanged: {
            labels: changedDatas,
            datasets: [
                {
                    label: 'EqRUB',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(255, 133, 133, 0.4)',
                    borderColor: 'rgba(255, 133, 133, 1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(255, 133, 133, 1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                    pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsRUB,
                    yAxisID: 'EqRUB',
                    pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                },
                {
                    label: 'EqUSD',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: changedValsUSD,
                    yAxisID: 'EqUSD',
                    pointBackgroundColor: 'rgba(75,192,192,1)',
                }
            ]
        }})
    }

    componentDidMount() {
        const mainkey = localStorage.getItem('mainkey')

        //fetch stats/bondsefficiencyrub RUB BONDS

        /* */

        fetch(process.env.REACT_APP_PROD_IP + '/stats/bondsefficiencyrub', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                let fundsArray = [];

                for (let i = 0; i < data.length; i++) {
                    fundsArray.push({
                        Name: data[i].Name,
                        P: Math.floor(data[i].PercentChanges * 100) / 100,
                        YP: Math.floor(data[i].YearlyInvestment * 100) / 100,
                        RUB: Math.floor(data[i].ClearMoney)
                    })
                }

                this.setState({
                    bondsefficiencyrub: fundsArray
                })
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //fetch stats/bondsefficiencyusd USD BONDS
        fetch(process.env.REACT_APP_PROD_IP + '/stats/bondsefficiencyusd', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {

                let fundsArray = [];

                for (let i = 0; i < data.length; i++) {
                    fundsArray.push({
                        Name: data[i].Name,
                        P: Math.floor(data[i].PercentChanges * 100) / 100,
                        YP: Math.floor(data[i].YearlyInvestment * 100) / 100,
                        USD: Math.floor(data[i].ClearMoney)
                    })
                }

                this.setState({
                    bondsefficiencyusd: fundsArray
                })
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //fetch stats/sharesefficiency RUB SHARES
        fetch(process.env.REACT_APP_PROD_IP + '/stats/sharesefficiencyrub', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {

                let fundsArray = [];

                for (let i = 0; i < data.length; i++) {
                    fundsArray.push({
                        Name: data[i].Name,
                        P: Math.floor(data[i].PercentChanges * 100) / 100,
                        YP: Math.floor(data[i].YearlyInvestment * 100) / 100,
                        RUB: Math.floor(data[i].ClearMoney)
                    })
                }

                this.setState({
                    sharesefficiencyrub: fundsArray
                })
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //fetch stats/sharesefficiency USD SHARES
        fetch(process.env.REACT_APP_PROD_IP + '/stats/sharesefficiencyusd', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {

                let fundsArray = [];

                for (let i = 0; i < data.length; i++) {
                    fundsArray.push({
                        Name: data[i].Name,
                        P: Math.floor(data[i].PercentChanges * 100) / 100,
                        YP: Math.floor(data[i].YearlyInvestment * 100) / 100,
                        USD: Math.floor(data[i].ClearMoney)
                    })
                }

                this.setState({
                    sharesefficiencyusd: fundsArray
                })
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //fetch clearmoneyandpercentsstats
        fetch(process.env.REACT_APP_PROD_IP + '/clearmoneyandpercentsstats', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                this.setState({ naturalDiffDataUSD: {
                    labels: data.map(a => Moment(a.StartDate).format('MMM-YY')),
                    datasets: [
                        {
                            label: 'cleardiffusd',
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            iyAxisID: 'left-y-axis',
                            data: data.map(a => Math.floor(a.NaturalDiffUSD))
                        }
                    ]
                }})

                this.setState({ naturalDiffDataRUB: {
                    labels: data.map(a => Moment(a.StartDate).format('MMM-YY')),
                    datasets: [
                        {
                            label: 'cleardiffrub',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(166,12,12,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(166,12,12,1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(166,12,12,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.NaturalDiffRUB))
                        }
                    ]
                }})

                this.setState({ linedataa: {
                    labels: data.map(a => Moment(a.StartDate).format('MMM-YY')),
                    datasets: [
                        {
                            label: 'PercentDiffUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,1)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(75,192,192,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => a.PercentDiffUSD),
                            pointBackgroundColor: 'rgba(75,192,192,1)',
                        },
                        {
                            label: 'PercentDiffRUB',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(255, 133, 133, 1)',
                            borderColor: 'rgba(255, 133, 133, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(255, 133, 133, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => a.PercentDiffRUB),
                            pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                        }
                    ]
                }})
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //fear and greed fetch 
        fetch(process.env.REACT_APP_PROD_IP + '/fearandgreed', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                this.setState({ fearandgreeddata: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'Fear and Greed',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(255, 133, 133, 0.4)',
                            borderColor: 'rgba(255, 133, 133, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(255, 133, 133, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => a.Value),
                            yAxisID: 'FearAndGreed',
                            pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                        }
                    ]
                }})

                this.setState({ fearandgreeddatachanged: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'Fear and Greed',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(255, 133, 133, 0.4)',
                            borderColor: 'rgba(255, 133, 133, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(255, 133, 133, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => a.Value),
                            yAxisID: 'FearAndGreed',
                            pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                        }
                    ]
                }})
            }
        })


        //equity fetch
        fetch(process.env.REACT_APP_PROD_IP + '/stats/equity', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                this.setState({ equitydata: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'EqRUB',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(255, 133, 133, 0.4)',
                            borderColor: 'rgba(255, 133, 133, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(255, 133, 133, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.EqRUB)),
                            yAxisID: 'EqRUB',
                            pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                        },
                        {
                            label: 'EqUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.EqUSD)),
                            yAxisID: 'EqUSD',
                            pointBackgroundColor: 'rgba(75,192,192,1)',
                        }
                    ]
                }})

                this.setState({ equitydatachanged: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'EqRUB',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(255, 133, 133, 0.4)',
                            borderColor: 'rgba(255, 133, 133, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(255, 133, 133, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.EqRUB)),
                            yAxisID: 'EqRUB',
                            pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                        },
                        {
                            label: 'EqUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.EqUSD)),
                            yAxisID: 'EqUSD',
                            pointBackgroundColor: 'rgba(75,192,192,1)',
                        }
                    ]
                }})

                this.setState({ usdDebtBtcFundsEquity: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'DebtUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(144, 233, 240, 1)',
                            borderColor: 'rgba(144, 233, 240, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(144, 233, 240, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(144, 233, 240, 1)',
                            pointHoverBorderColor: 'rgba(144, 233, 240, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.DebtUSD)),
                            pointBackgroundColor: 'rgba(144, 233, 240, 1)',
                        },
                        {
                            label: 'FundsUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(237, 133, 237, 1)',
                            borderColor: 'rgba(237, 133, 237, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(237, 133, 237, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalFundsUSD)),
                            pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                        },
                        {
                            label: 'CurrentBTCinUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(245, 204, 115, 1)',
                            borderColor: 'rgba(245, 204, 115, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(245, 204, 115, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(245, 204, 115, 1)',
                            pointHoverBorderColor: 'rgba(245, 204, 115, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.CurrentBTCinUSD)),
                            pointBackgroundColor: 'rgba(245, 204, 115, 1)',
                        },
                        {
                            label: 'EqUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(189, 232, 146, 1)',
                            borderColor: 'rgba(189, 232, 146, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(189, 232, 146, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(189, 232, 146, 1)',
                            pointHoverBorderColor: 'rgba(189, 232, 146, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.EqUSD)),
                            pointBackgroundColor: 'rgba(189, 232, 146, 1)',
                        }
                    ]
                }})

                this.setState({ usdDebtBtcFundsEquitychanged: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'DebtUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(144, 233, 240, 1)',
                            borderColor: 'rgba(144, 233, 240, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(144, 233, 240, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(144, 233, 240, 1)',
                            pointHoverBorderColor: 'rgba(144, 233, 240, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.DebtUSD)),
                            pointBackgroundColor: 'rgba(144, 233, 240, 1)',
                        },
                        {
                            label: 'FundsUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(237, 133, 237, 1)',
                            borderColor: 'rgba(237, 133, 237, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(237, 133, 237, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalFundsUSD)),
                            pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                        },
                        {
                            label: 'CurrentBTCinUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(245, 204, 115, 1)',
                            borderColor: 'rgba(245, 204, 115, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(245, 204, 115, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(245, 204, 115, 1)',
                            pointHoverBorderColor: 'rgba(245, 204, 115, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.CurrentBTCinUSD)),
                            pointBackgroundColor: 'rgba(245, 204, 115, 1)',
                        },
                        {
                            label: 'EqUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(189, 232, 146, 1)',
                            borderColor: 'rgba(189, 232, 146, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(189, 232, 146, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(189, 232, 146, 1)',
                            pointHoverBorderColor: 'rgba(189, 232, 146, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.EqUSD)),
                            pointBackgroundColor: 'rgba(189, 232, 146, 1)',
                        }
                    ]
                }})

                this.setState({ fundsusdrub: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'FundsRUB',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(255, 133, 133, 1)',
                            borderColor: 'rgba(255, 133, 133, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(255, 133, 133, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalFundsRUB)),
                            yAxisID: 'FundsRUB',
                            pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                        },
                        {
                            label: 'FundsUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(75,192,192,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalFundsUSD)),
                            yAxisID: 'FundsUSD',
                            pointBackgroundColor: 'rgba(75,192,192,1)',
                        }
                    ]
                }})

                this.setState({ fundsusdrubchanged: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'FundsRUB',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(255, 133, 133, 1)',
                            borderColor: 'rgba(255, 133, 133, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(255, 133, 133, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderColor: 'rgba(255, 133, 133, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalFundsRUB)),
                            yAxisID: 'FundsRUB',
                            pointBackgroundColor: 'rgba(255, 133, 133, 1)',
                        },
                        {
                            label: 'FundsUSD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(75,192,192,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalFundsUSD)),
                            yAxisID: 'FundsUSD',
                            pointBackgroundColor: 'rgba(75,192,192,1)',
                        }
                    ]
                }})

                this.setState({ bondssharesbtcusd: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'Shares USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(189, 232, 146,0.4)',
                            borderColor: 'rgba(189, 232, 146,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(189, 232, 146,1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(189, 232, 146,1)',
                            pointHoverBorderColor: 'rgba(189, 232, 146,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalSharesUSD)),
                            pointBackgroundColor: 'rgba(189, 232, 146, 1)',
                        },
                        {
                            label: 'Bonds USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(245, 204, 115, 1)',
                            borderColor: 'rgba(245, 204, 115, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(245, 204, 115, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(245, 204, 115, 1)',
                            pointHoverBorderColor: 'rgba(245, 204, 115, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalBondsUSD)),
                            pointBackgroundColor: 'rgba(245, 204, 115, 1)',
                        },
                        {
                            label: 'BTC in USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(237, 133, 237, 1)',
                            borderColor: 'rgba(237, 133, 237, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(237, 133, 237, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.CurrentBTCinUSD)),
                            pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                        },
                        {
                            label: 'Cash in USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(237, 133, 237, 1)',
                            borderColor: 'rgba(237, 133, 237, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(237, 133, 237, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.BrokerCashUSD)),
                            pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                        },
                        {
                            label: 'Cash in USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(237, 133, 237, 1)',
                            borderColor: 'rgba(237, 133, 237, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(237, 133, 237, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalETFUSD)),
                            pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                        }
                    ]
                }})

                this.setState({ bondssharesbtcusdchanged: {
                    labels: data.map(a => Moment(a.Date).format('DD-MMM-YY')),
                    datasets: [
                        {
                            label: 'Shares USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(189, 232, 146,0.4)',
                            borderColor: 'rgba(189, 232, 146,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(189, 232, 146,1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(189, 232, 146,1)',
                            pointHoverBorderColor: 'rgba(189, 232, 146,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalSharesUSD)),
                            pointBackgroundColor: 'rgba(189, 232, 146, 1)',
                        },
                        {
                            label: 'Bonds USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(245, 204, 115, 1)',
                            borderColor: 'rgba(245, 204, 115, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(245, 204, 115, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(245, 204, 115, 1)',
                            pointHoverBorderColor: 'rgba(245, 204, 115, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalBondsUSD)),
                            pointBackgroundColor: 'rgba(245, 204, 115, 1)',
                        },
                        {
                            label: 'BTC in USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(237, 133, 237, 1)',
                            borderColor: 'rgba(237, 133, 237, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(237, 133, 237, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.CurrentBTCinUSD)),
                            pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                        },
                        {
                            label: 'Cash in USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(237, 133, 237, 1)',
                            borderColor: 'rgba(237, 133, 237, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(237, 133, 237, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.BrokerCashUSD)),
                            pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                        },
                        {
                            label: 'ETF in USD',
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(237, 133, 237, 1)',
                            borderColor: 'rgba(237, 133, 237, 1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(237, 133, 237, 1)',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderColor: 'rgba(237, 133, 237, 1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: data.map(a => Math.floor(a.TotalETFUSD)),
                            pointBackgroundColor: 'rgba(237, 133, 237, 1)',
                        }
                    ]
                }})
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //earnings per month
        fetch(process.env.REACT_APP_PROD_IP + '/stats/earnings', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                this.setState({ earningsPerMonth: {
                    labels: data.map(a => Moment(a.Date).format('MMM-YY')),
                    datasets: [
                        {
                            label: 'Earnings Per Month',
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            data: data.map(a => Math.floor(a.Earnings))
                        }
                    ]
                }})
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //costs per month
        fetch(process.env.REACT_APP_PROD_IP + '/stats/costs', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                this.setState({ costsPerMonth: {
                    labels: data.map(a => Moment(a.Date).format('MMM-YY')),
                    datasets: [
                        {
                            label: 'Costs Per Month',
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            data: data.map(a => Math.floor(a.Costs))
                        }
                    ]
                }})
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //fetch cost by category
        fetch(process.env.REACT_APP_PROD_IP + '/stats/costsbycategory', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {

            // if (data[0].P != null) {
            //     console.log("data[0].P != null")
            // }

            // if (data[0].P.length < 1) {
            //     console.log("data[0].P.length < 1")
            // }

            // if (data[0].P) {
            //     console.log("data[0].P")
            // }

            // if (data[0].P.touched) {
            //     console.log("data[0].P.touched")
            // }


            // if (data[0].P = null) {
            //     console.log("data[0].P = null")
            // }

            // if (data[0].P.length >= 1) {
            //     console.log("data[0].P.length >= 1")
            // }

            // if (!data[0].P) {
            //     console.log("!data[0].P")
            // }

            // if (!data[0].P.touched) {
            //     console.log("!data[0].P.touched")
            // }

            if (data != null) {
                console.log("data: ", data)

                //THIS MONTH
                let priceArray = [];

                if (data[0].P) {
                    for (let i = 0; i < data[0].P.length; i++) {
                        priceArray.push(data[0].P[i].TotalCosts)
                    }
                }
                
                console.log("priceArray: ", priceArray)

                let categoryNameArray = [];

                if (data[0].P) {
                    for (let i = 0; i < data[0].P.length; i++) {
                        categoryNameArray.push(data[0].P[i].CategoryName)
                    }
                }

                this.setState({
                    donutthismonthdate: data[0].Val
                })

                this.setState({ 
                    donutthismonth: {
                        datasets: [{
                            data: priceArray,
                            backgroundColor: [
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                            ], 
                        }],
                        labels: categoryNameArray
                    }
                })

                let tableWithTheData = [];

                if (data[0].P) {
                    for (let i = 0; i < data[0].P.length; i++) {
                        tableWithTheData.push(
                            {'Category': data[0].P[i].CategoryName, 'cost': data[0].P[i].TotalCosts},
                        )
                    }
                } else {
                    tableWithTheData.push(
                        {'Category': '', 'cost': 0}
                    )
                }

                this.setState({
                    tabledatathismonth: tableWithTheData,
                })

                //LAST MONTH
                let priceArrayLast = [];

                for (let i = 0; i < data[1].P.length; i++) {
                    priceArrayLast.push(data[1].P[i].TotalCosts)
                }

                let categoryNameArrayLast = [];

                for (let i = 0; i < data[1].P.length; i++) {
                    categoryNameArrayLast.push(data[1].P[i].CategoryName)
                }

                this.setState({
                    donutlastmonthdate: data[1].Val
                })

                this.setState({ 
                    donutlastmonth: {
                        datasets: [{
                            data: priceArrayLast,
                            backgroundColor: [
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                            ], 
                        }],
                        labels: categoryNameArrayLast
                    }
                })

                let tableWithTheDataLast = [];

                for (let i = 0; i < data[1].P.length; i++) {
                    tableWithTheDataLast.push(
                        {'Category': data[1].P[i].CategoryName, 'cost': data[1].P[i].TotalCosts},
                    )
                }

                this.setState({
                    tabledatalastmonth: tableWithTheDataLast,
                })

                //LAST LAST MONTH

                let priceArrayLastLast = [];

                for (let i = 0; i < data[2].P.length; i++) {
                    priceArrayLastLast.push(data[2].P[i].TotalCosts)
                }

                let categoryNameArrayLastLast = [];

                for (let i = 0; i < data[2].P.length; i++) {
                    categoryNameArrayLastLast.push(data[2].P[i].CategoryName)
                }

                this.setState({
                    donutlastlastmonthdate: data[2].Val
                })

                this.setState({ 
                    donutlastlastmonth: {
                        datasets: [{
                            data: priceArrayLastLast,
                            backgroundColor: [
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                                'rgba(237, 133, 237, 0.3)',
                                'rgba(144, 233, 240, 0.3)',
                            ], 
                        }],
                        labels: categoryNameArrayLastLast
                    }
                })

                let tableWithTheDataLastLast = [];

                for (let i = 0; i < data[2].P.length; i++) {
                    tableWithTheDataLastLast.push(
                        {'Category': data[2].P[i].CategoryName, 'cost': data[2].P[i].TotalCosts},
                    )
                }

                this.setState({
                    tabledatalastlastmonth: tableWithTheDataLastLast,
                })
                console.log("tableWithTheDataLastLast: ", tableWithTheDataLastLast)
            }
        })
        .catch((error) => {
            console.log(error)
            // console.log("bububub")
        });

        

        //get shares rub analytics
        fetch(process.env.REACT_APP_PROD_IP + '/stats/rubsharestypes', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                this.setState({ sharesrubcategory: data })
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //get shares rub category
        fetch(process.env.REACT_APP_PROD_IP + '/stats/rubsharesanalytics', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                this.setState({ sharesrubanalytics: data })
            }
        })
        .catch((error) => {
            console.log(error)
        });

        //statssingleparms fetch
        fetch(process.env.REACT_APP_PROD_IP + '/statssingleparms', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then(data => {
            if (data != null) {
                this.setState({ singlestats: data })

                this.setState({ 
                    donutcurrent: {
                        datasets: [{
                            data: [
                                Math.floor((data.TotalSharesUSD / data.TotalFundsUSD * 100) * 100) / 100,
                                Math.floor((data.TotalBondsUSD / data.TotalFundsUSD * 100) * 100) / 100,
                                Math.floor((data.TotalBrokerCashUSD / data.TotalFundsUSD * 100) * 100) / 100,
                                Math.floor((data.TotalETFUSD / data.TotalFundsUSD * 100) * 100) / 100,
                            ],
                            backgroundColor: [
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75, 192, 192, 0.3)',
                                'rgba(189, 232, 146, 0.3)',
                                'rgba(245, 204, 115, 0.3)',
                            ], 
                        }],
                        labels: [
                            'Shares',
                            'Bonds',
                            'Cash',
                            'ETF',
                        ]
                    }
                })

                this.setState({ 
                    donutgram: {
                        datasets: [{
                            data: [
                                Math.floor(data.PercentSharesGRAM * 100) / 100,
                                Math.floor(data.PercentBondsGRAM * 100) / 100,
                            ],
                            backgroundColor: [
                                'rgba(255, 133, 133, 0.3)',
                                'rgba(75,192,192, 0.3)',
                            ], 
                        }],
                        labels: [
                            'Shares',
                            'Bonds + CASH',
                        ]
                    }
                })

            }
        })
        .catch((error) => {
            console.log(error)
        });
    }
}