import React, { useState, useEffect } from 'react';
// import Moment from 'moment'
import Loader from '../Loader'
//import { Link, BrowserRouter as Router, Route } from 'react-router-dom';

import TaxTable from './TaxTable';

// import Swal from 'sweetalert2'

// import { format } from "date-fns";

// import { Paper, TextField } from "@material-ui/core";
// import Select from '@material-ui/core/Select';
// import MenuItem from "@material-ui/core/MenuItem";
// import Input from "@material-ui/core/Input";
// import { EditingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { useHistory } from "react-router-dom";

function App() {
    const history = useHistory();
    const [rows, setRows] = useState([])
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        const mainkey = localStorage.getItem('mainkey')
        fetch(process.env.REACT_APP_PROD_IP + '/taxusdshares', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
              return response.json();
              //console.log('1');
            } else {
                //console.log('2');
                localStorage.clear()
                history.push({
                    pathname: '/login',
                    state: { detail: "sdf" },
                })
                window.location.reload()
                throw Error(response.statusText);
            }
        })
        .then(rows => {
            setRows(rows)
            setLoading(false)
            // console.log("rows: ", rows)
        })
    }, [history])

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////       DELETE     ////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // function SweetAlert(item) {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!'
    //     }).then((result) => {
    //         if (result.value) {
    //             deleteFund(item)
    //             Swal.fire(
    //                 'Deleted!',
    //                 'Your file has been deleted.',
    //                 'success'
    //             )
    //         }
    //     });
    // }

    // function deleteFund (item) {
    //     const mainkey = localStorage.getItem('mainkey')
    //     const requestOptions = {
    //         method: 'DELETE',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json',
    //             'Token': mainkey
    //         },
    //         body: JSON.stringify({ id: item })
    //     };

    //     fetch(process.env.REACT_APP_PROD_IP + '/costs/' + item, requestOptions).then((response) => {
    //         return response.json();
    //     })
    //     .then((result) => {
    //         // do what you want with the response here
    //     });
	   
    //     //window.location.reload(false);
    // }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////       SAVE     /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // function addFund(value) {
    //     console.log("!!! value", value)
    //     setRows(rows.concat([{
    //         id: Date.now(),
    //         amount: value[0].amount,
    //         category: value[0].category,
    //         source: value[0].source,
    //     }]))
    //     saveAddedFund(value)
    // }

    // function saveAddedFund(value) {
    //     const mainkey = localStorage.getItem('mainkey')
    //     console.log("VALUE", value)
    //     console.log("VALUE0", value[0])
    //     console.log("VALUE1", value[1])

    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json',
    //             'Token': mainkey
    //         },
    //         body: JSON.stringify({ 
    //             "amount": Number(value[0].amount),
    //             "category": value[0].category,
    //             "source": value[0].source,
    //             "datetime": Moment(value[0].datetime).format()
    //         })
    //     };
    //     console.log("requestOptions", requestOptions)
    //     //"datetime": Moment(value[0].datetime).format("MM.DD.yyyy")

    //     fetch(process.env.REACT_APP_PROD_IP + '/costs', requestOptions).then((response) => {
    //         return response.json();
    //     })
    //     .then((result) => {
        
    //     });

    //     //window.location.reload(false);
    // }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////      UPDATE    /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // function changeFund(value) {
    //     editedFund(value)
    // }

    // function editedFund(value) {
    //     const mainkey = localStorage.getItem('mainkey')
    //     console.log("value", value)
    //     let blablabla

    //     Object.entries(value).map((item, i) => {
    //         console.log("i", i)
    //         console.log("item", item)
    //         console.log("item[1]", item[1])

    //         blablabla = item[1]
    //         return blablabla
    //     });

    //     console.log("blablabla", blablabla)
        
    //     let standjson = {
    //          "id": Number(Object.keys(value)[0])
    //     }

    //     console.log("standjson", standjson)

    //     const combined = { ...blablabla, ...standjson }

    //     if (combined.amount) {
    //         combined.amount = Number(combined.amount)
    //         console.log("combined.anount", combined.amount)
    //     }

    //     if (combined.datetime) {
    //         combined.datetime = Moment(combined.datetime).format('')
    //         console.log("combined.datepurchase", combined.datetime)
    //     }

    //     console.log("combined", combined)

    //     const requestOptions = {
    //         method: 'PUT',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json',
    //             'Token': mainkey
    //         },
    //         body: JSON.stringify(combined)
    //     };
    //     console.log("requestOptions", requestOptions)

    //     fetch(process.env.REACT_APP_PROD_IP + '/costs/' + standjson.id, requestOptions).then((response) => {
    //         return response.json();
    //     }).then((result) => {
    //         // do what you want with the response here
    //     });
        
    //   //window.location.reload(false);
    // }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////   COMMIT CHANGES  ////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // const commitChanges = ({ added, changed, deleted }) => {
    //     let changedRows;
    //     if (added) {
    //       const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 + Math.floor(Math.random() * Math.floor(34534345)) : 0;
    //       changedRows = [
    //         ...added.map((row, index) => ({
    //           id: startingAddedId + index,
    //           ...row,
    //         })),
    //         ...rows,
    //       ];
    //       console.log("ADDED")
    //       console.log("added", added)
    //       console.log("changedRows", changedRows)
    //       addFund(added)
    //     }
    //     if (changed) {
    //       changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    //       changeFund(changed)
    //       console.log("changedRows", changedRows)
    //       console.log("changed", changed)
    //       console.log("CHANGED")
    //     }
    //     if (deleted) {
    //         const deletedSet = new Set(deleted);
    //         console.log("rows", rows)
    //         changedRows = rows.filter(row => !deletedSet.has(row.id));
    //         console.log("deletedSet", deletedSet)
    //         console.log("changedRows", changedRows)
    //         SweetAlert(deleted[0])
    //     }
    //     setRows(changedRows);
    // };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////     RENDER     /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
        {loading && <Loader />}
        {rows && rows.length ? (
            <div>
                <TaxTable data={rows} />
            </div>
        ) : (
            loading ? null : <p>No Funds</p>
        )}
        </div>
    )
}

export default App
