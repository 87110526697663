import React, {Component} from 'react';
import Moment from 'moment'
import Swal from 'sweetalert2'
// import { func } from 'prop-types';

export default class TaxTable extends Component {
 
    constructor(props){
        super(props);
        // this.getHeader = this.getHeader.bind(this);
        // this.getRowsData = this.getRowsData.bind(this);
        // this.getKeys = this.getKeys.bind(this);

        this.state = {
            itemFormState: {},
            rowFormState: false,
            addItemAction: 'buy',
            addItemNumberOfShares: 0,
            addItemDate: Moment().format(),
            addItemUSDPrice: 0,
            addItemTotalUSD: 0,
            addRowName: '',
            addRowTicker: '',
            addRowNumberOfShares: 0,
            addRowDate: Moment().format(),
            addRowUSDPrice: 0,
            addRowTotalUSD: 0,
        }
    }

    showAddForm(setid) {
        let name = 'setnumber' + setid
        this.setState({
            itemFormState: {
                [name]: true,
            }
        })
    }

    hideAddForm() {
        this.setState({
            itemFormState: false,
            addItemAction: 'buy',
            addItemNumberOfShares: 0,
            addItemDate: Moment().format(),
            addItemUSDPrice: 0,
            addItemTotalUSD: 0,
        })
    }

    showRowForm() {
        this.setState({
            rowFormState: true
        })
    }

    hideRowForm() {
        this.setState({
            rowFormState: false,
            addRowName: '',
            addRowTicker: '',
            addRowNumberOfShares: 0,
            addRowDate: Moment().format(),
            addRowUSDPrice: 0,
            addRowTotalUSD: 0,
        })
    }

    closeRow(key) {
        console.log("key: ", key)

        let setid = key.setid
        console.log("setid: ", setid)

        let state = key.state
        console.log("state: ", state)

        let newstate = true

        if (state === 'open') {
            newstate = 'close'
        } 

        if (state === 'close') {
            newstate = 'open'
        } 
        
        console.log("newstate: ", newstate)

        const mainkey = localStorage.getItem('mainkey')
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify({ 
                "setid": setid,
                "state": newstate
            })
        };

        fetch(process.env.REACT_APP_PROD_IP + '/taxusdsharesrow/'+setid, requestOptions).then((response) => {
            return response.json();
        })
        .then((result) => {
            
        });
    }

    saveItem(key) {
        console.log("SAVE ITEM")
        console.log("key: ", key)
        console.log("this.state.addItemAction", this.state.addItemAction)
        console.log("this.state.addItemNumberOfShares", this.state.addItemNumberOfShares)
        console.log("this.state.addItemDate", this.state.addItemDate)
        console.log("this.state.addItemUSDPrice", this.state.addItemUSDPrice)
        console.log("this.state.addItemTotalUSD", this.state.addItemTotalUSD)

        const mainkey = localStorage.getItem('mainkey')
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify({ 
                "setid": key.setid,
                "state": "open",
                "name": key.name,
                "ticker": key.ticker,
                "numberofshares": Number(this.state.addItemNumberOfShares),
                "actiontype": this.state.addItemAction,
                "datetime": Moment(this.state.addItemDate).format(''),
                "dollarondate": Number(this.state.addItemUSDPrice),
                "amountusd": Number(this.state.addItemTotalUSD),
            })
        };

        fetch(process.env.REACT_APP_PROD_IP + '/taxusdshares', requestOptions).then((response) => {
            return response.json();
        })
        .then((result) => {
            
        });
    }

    saveRow() {
        console.log("SAVE ROW")
        console.log("this.state.addRowName", this.state.addRowName)
        console.log("this.state.addRowTicker", this.state.addRowTicker)
        console.log("this.state.addRowNumberOfShares", this.state.addRowNumberOfShares)
        console.log("this.state.addRowDate", this.state.addRowDate)
        console.log("this.state.addRowUSDPrice", this.state.addRowUSDPrice)
        console.log("this.state.addRowTotalUSD", this.state.addRowTotalUSD)

        let setid = Math.round(Math.random() * (999999999 - 1) + 1)
        console.log("setid: ", setid)

        const mainkey = localStorage.getItem('mainkey')
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify({ 
                "setid": setid,
                "state": "open",
                "name": this.state.addRowName,
                "ticker": this.state.addRowTicker,
                "numberofshares": Number(this.state.addRowNumberOfShares),
                "actiontype": 'buy',
                "datetime": Moment(this.state.addRowDate).format(''),
                "dollarondate": Number(this.state.addRowUSDPrice),
                "amountusd": Number(this.state.addRowTotalUSD),
            })
        };

        fetch(process.env.REACT_APP_PROD_IP + '/taxusdshares', requestOptions).then((response) => {
            return response.json();
        })
        .then((result) => {
            
        });
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })
    onDateChange = e => this.setState({ [e.target.name]: Moment(e.target.value).format('') })

    getAddRowsBlock = function(){
        return (
            <div>
                <div className="mb-4">
                    <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" onClick={e => this.showRowForm()}>ADD SET</button>
                </div>
                {
                    this.state.rowFormState ? 
                        <div className="taxSetItems mb-2 p-2">
                            <div className="taxItem mt-2 p-2">
                            <table>
                                <tbody>
                                <tr>
                                    <td>Name:</td>
                                    <td><input className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" name="addRowName" onChange={this.onChange} type="text" /></td>
                                </tr>
                                <tr>
                                    <td>Ticker:</td>
                                    <td><input className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" name="addRowTicker" onChange={this.onChange} type="text" /></td>
                                </tr>
                                <tr>
                                    <td><span>Action:</span></td>
                                    <td>
                                        <div className="inline-block relative w-64">
                                            <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                                <option>buy</option>
                                                <option>sell</option>
                                            </select>
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Number of shares:</span></td>
                                    <td><input className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" onChange={this.onChange} name="addRowNumberOfShares" type="text" /></td>
                                </tr>
                                <tr>
                                    <td><span>Date:</span></td>
                                    <td><input className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" onChange={this.onDateChange} name="addRowDate" type="date" /></td>
                                </tr>
                                <tr>
                                    <td><span>Курс доллара на указанную дату:</span></td>
                                    <td><input className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" onChange={this.onChange} name="addRowUSDPrice" type="text" /></td>
                                </tr>
                                <tr>
                                    <td><span>Общая сумма в долларах:</span></td>
                                    <td><input className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" onChange={this.onChange} name="addRowTotalUSD" type="text" /></td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="flex items-center">
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-10" type="button" onClick={e => this.saveRow()}>SAVE</button>
                                <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" type="button" onClick={e => this.hideRowForm()}>CANCEL</button>
                            </div>
                            </div>
                        </div>
                    : 
                    ''
                }
            </div>
        )
    }

    getRowsData = function(){
        var items = this.props.data;
        // console.log(this.props.data)

        return items.map((key, index) => {
            return (
            <div key={index} className={key.state === 'open' ? 'taxSetItems taxSetItemsOpened mb-4 gap-4 p-2' : 'taxSetItems taxSetItemsClosed mb-4 gap-4 p-2'} >
                <div className="grid grid-cols-2">
                    <div className="name">{items[index].name} ({items[index].ticker})</div>
                    <div className="text-right">
                        <button className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2" type="button" onClick={e => this.closeRow(key)}>{key.state === 'open' ? 'Close Set' : 'Open Set'}</button>
                        <button className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" type="button" onClick={e => this.showAddForm(key.setid)}>add new</button>
                        <span className={items[index].diffusd > 0 ? 'positiveAmountMount ml-6' : 'negativeAmountMount ml-6'}>USD: {Math.floor(items[index].diffusd)}</span>
                        <span className={items[index].diffrub > 0 ? 'positiveAmountMount ml-6' : 'negativeAmountMount ml-6'}>RUB: {Math.floor(items[index].diffrub)}</span>
                        <span className="ml-6">TAX: {Math.floor(items[index].taxsum * 100) / 100}</span>
                    </div>
                </div>
                {
                    this.state.itemFormState['setnumber'+key.setid] ? 
                        <div className="taxItem mt-2 p-2">
                            <table>
                                <tbody>
                                <tr>
                                    <td><span>Action:</span></td>
                                    <td>
                                        <select name="addItemAction" onChange={this.onChange}>
                                            <option>buy</option>
                                            <option>sell</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Number of shares:</span></td>
                                    <td><input onChange={this.onChange} name="addItemNumberOfShares" type="text" /></td>
                                </tr>
                                <tr>
                                    <td><span>Date:</span></td>
                                    <td><input onChange={this.onDateChange} name="addItemDate" type="date" /></td>
                                </tr>
                                <tr>
                                    <td><span>Курс доллара на указанную дату:</span></td>
                                    <td><input onChange={this.onChange} name="addItemUSDPrice" type="text" /></td>
                                </tr>
                                <tr>
                                    <td><span>Общая сумма в долларах:</span></td>
                                    <td><input onChange={this.onChange} name="addItemTotalUSD" type="text" /></td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="mt-2">
                                <button className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2" type="button" onClick={e => this.saveItem(key)}>SAVE</button>
                                <button className="text-xs bg-blue-300 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" type="button" onClick={e => this.hideAddForm()}>CANCEL</button>
                            </div>
                        </div>
                    : 
                    ''
                }
                <RenderInternal data={key} />
            </div>
            )
        })
    }

    render() {
        return (
            <div>
                {this.getAddRowsBlock()}
                {this.getRowsData()}
            </div>
        );
    }
}

const RenderInternal = (props) => {
    return props.data.entries.map((keyy, indexx) => {
        return (
            <div key={indexx} className="taxItem mt-2 p-2">
                <div>
                    <table className="w-full">
                        <tr>
                            <td className="tdtype">{keyy.actiontype}</td>
                            <td className="tdnumberofshares">Shares: {keyy.numberofshares}</td>
                            <td className="tddate">{Moment(keyy.datetime).format('LL')}</td>
                            <td className="tdrate">Rate: ${keyy.dollarondate}</td>
                            <td className="tdamountrub">RUB: ₽{Math.floor(keyy.amountrub)}</td>
                            <td className="tdamountusd">USD: ${keyy.amountusd}</td>
                            <td className="mt-2 lastrow">
                                <button className="text-xs bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded" type="button" onClick={e => SweetAlert(keyy.id)}>REMOVE</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        )
    })
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////                     DELETE ITEM                         /////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function SweetAlert(item) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.value) {
            removeTaxItem(item)
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
        }
    });
}

function removeTaxItem (item) {
    const mainkey = localStorage.getItem('mainkey')
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Token': mainkey
        },
        body: JSON.stringify({ id: item })
    };

    fetch(process.env.REACT_APP_PROD_IP + '/taxusdshares/' + item, requestOptions).then((response) => {
        return response.json();
    })
    .then((result) => {
        // do what you want with the response here
    });
    
    //window.location.reload(false);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////                       ADD  ITEM                         /////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// function addNewItem (props) {
//     console.log("props: ", props);
// }

