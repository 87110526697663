import React, { useState } from 'react';

import FundsRubBonds from './Funds/FundsRubBonds'
import FundsRubShares from './Funds/FundsRubShares'
import FundsRubETF from './Funds/FundsRubETF'
import FundsUsdShares from './Funds/FundsUsdShares'
import FundsUsdBonds from './Funds/FundsUsdBonds'
import InvestCash from './Funds/InvestCash'
import Costs from './Funds/Costs'
import Earnings from './Funds/Earnings'
import ForecastForFunds from './Funds/ForecastForFunds'
import Home from './Funds/Home'
import Login from './Funds/Login'
import Charts from './Funds/Charts'
import TaxesUSDShares from './Funds/TaxesUSDShares'
//import props from 'prop-types';

import { NavLink, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
// import { useHistory } from "react-router-dom";
//import {useLocation} from 'react-router-dom'

//import { SelectionState } from '@devexpress/dx-react-grid';



const IndexPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">Home Page</h1>
      <Home />
    </div>
  );
};

const BondsRubPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">RUB BONDS</h1>
      <FundsRubBonds />
    </div>
  );
};

const SharesRubPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">RUB SHARES</h1>
      <FundsRubShares />
    </div>
  );
};

const ETFRubPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">RUB ETF</h1>
      <FundsRubETF />
    </div>
  );
};

const SharesUsdPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">USD SHARES</h1>
      <FundsUsdShares />
    </div>
  );
};

const BondsUsdPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">USD BONDS</h1>
      <FundsUsdBonds />
    </div>
  );
};

const InvestCashPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">INVEST CASH</h1>
      <InvestCash />
    </div>
  );
};

const CostsPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">Costs</h1>
      <Costs />
    </div>
  );
};

const EarningsPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">Earnings</h1>
      <Earnings />
    </div>
  );
};

const ForecastForFundsPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">Forecash For Funds</h1>
      <ForecastForFunds />
    </div>
  );
};

const LoginPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">Login</h1>
      <Login />
    </div>
  );
};

const ChartsPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">Charts</h1>
      <Charts />
    </div>
  );
};

const TaxUSDSharesPage = () => {
  return (
    <div className='wrapper'>
      <h1 className="font-sans font-semibold text-2xl py-5">Taxes USD Shares</h1>
      <TaxesUSDShares />
    </div>
  );
};

const App = () => {

  //let location = useLocation()
  // let history = useHistory();

  let myToken = localStorage.getItem('mainkey')
  console.log('myToken', myToken);

  const [wehavetoken, setWehavetoken] = useState(myToken !== null ? true : false)
  console.log('wehavetoken?', wehavetoken);

  const [navigate, setNavigate] = useState(false)
  //console.log(navigate)

  // useEffect(() => {
    // if (myToken !== null) {
    //   setWehavetoken(true)
    // }

    // if (history.state.state.detail) {
    //   console.log("PIZDA")
    // }
  // })

  function logout (e) {
    e.preventDefault();
    localStorage.clear()
    //history.push("/login");
    setNavigate(true)
    setWehavetoken(false)
  }

  function toggleHeader(e) {
    e.preventDefault();
    document.getElementById("nav-content").classList.toggle("hidden");
  }

  //history.state.state.detail

//   useEffect(() => {
//     console.log(location.pathname); // result: '/secondpage'
//     console.log(location.search); // result: '?query=abc'
//     console.log(location.state.detail); // result: 'some_value'
//  }, []);
  

  return (
    <Router>

    {navigate ? <Redirect to="/login" /> : ''}

    <section className="App">
      <nav className="flex items-center justify-between flex-wrap bg-gray-800 p-2 fixed w-full z-10 top-0">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <a className="text-white no-underline hover:text-white hover:no-underline" href="#">
            <span className="text-2xl pl-2"><i className="em em-grinning"></i> Muffin</span>
          </a>
        </div>

        <div className="block lg:hidden">
          <button onClick={toggleHeader} id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>

        <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0" id="nav-content">
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/charts">Charts</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/taxusdshares">TAX</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/">Home</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/costs">Costs</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/earnings">Earnings</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/bondsrub">Bonds RUB</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/sharesrub">Shares RUB</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/etfrub">ETF RUB</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/bondsusd">Bonds USD</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/sharesusd">Shares USD</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/investcash">Invest</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/fff">FFF</NavLink></li> : ''}
            {wehavetoken ? <li className="mr-6"><button onClick={logout} className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4">Log Out</button></li> : ''}
            {!wehavetoken ? <li className="mr-3"><NavLink activeClassName="activenavi" className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" exact to="/login">Login</NavLink></li> : ''}
          </ul>
        </div>
      </nav>

      <div className="container mx-auto mt-16">
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/charts" component={ChartsPage} />
        <Route exact path="/bondsrub" component={BondsRubPage} />
        <Route exact path="/sharesrub" component={SharesRubPage} />
        <Route exact path="/etfrub" component={ETFRubPage} />
        <Route exact path="/sharesusd" component={SharesUsdPage} />
        <Route exact path="/bondsusd" component={BondsUsdPage} />
        <Route exact path="/investcash" component={InvestCashPage} />
        <Route exact path="/costs" component={CostsPage} />
        <Route exact path="/earnings" component={EarningsPage} />
        <Route exact path="/fff" component={ForecastForFundsPage} />
        <Route exact path="/taxusdshares" component={TaxUSDSharesPage} />
        <Route exact path="/login" component={LoginPage} />
      </div>
    </section>
    </Router>
  );
};

export default App;