import React, {useState, useEffect} from 'react';
import Loader from '../Loader'
import { useHistory } from "react-router-dom";

function App() {

    const history = useHistory();

    const [funds, setFunds] = useState({})
    const [loading, setLoading] = React.useState(true)

    const [tinkoff, setTinkoff] = useState('')
    const [sber, setSber] = useState('')
    const [btc, setBtc] = useState('')
    const [mortg, setMortg] = useState('')
    const [cash, setCash] = useState('')
    const [ldsdebt, setLdsdebt] = useState('')
    const [vtb, setVtb] = useState('')
    const [yandexmoney, setYandexmoney] = useState('')
    const [epayments, setEpayments] = useState('')
    const [paypal, setPaypal] = useState('')
    const [raiff, setRaiff] = useState('')

    const [brokerusd, setBrokerusd] = useState('')
    const [brokerrub, setBrokerrub] = useState('')
    const [iisusd, setIisusd] = useState('')
    const [iisrub, setIisrub] = useState('')

    useEffect(() => {
        const mainkey = localStorage.getItem('mainkey')
        fetch(process.env.REACT_APP_PROD_IP + '/home', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })

        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
                //console.log('1');
              return response.json();
            } else {
                //console.log('2');
                localStorage.clear()
                history.push({
                    pathname: '/login',
                    state: { detail: "sdf" },
                })
                window.location.reload()
                throw Error(response.statusText);
            }
        })
        .then(funds => {
            setFunds(funds)
            setTinkoff(funds.tinkoff)
            setSber(funds.sber)
            setBtc(funds.btc)
            setMortg(funds.mortg)
            setCash(funds.cash)
            setLdsdebt(funds.ldsdebt)
            setVtb(funds.vtb)
            setYandexmoney(funds.yandexmoney)
            setEpayments(funds.epayments)
            setPaypal(funds.paypal)
            setRaiff(funds.raiff)
            setBrokerusd(funds.brokerusd)
            setBrokerrub(funds.brokerrub)
            setIisusd(funds.iisusd)
            setIisrub(funds.iisrub)
            setLoading(false)
        }).catch(function (error) {
            console.log("ERROE: ", error);
        })
    }, [history])

    function submitHandler(event) {
        event.preventDefault();

        const mainkey = localStorage.getItem('mainkey')
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify({ 
                "tinkoff": Number(tinkoff),
                "sber": Number(sber),
                "btc": Number(btc),
                "mortg": Number(mortg),
                "cash": Number(cash),
                "ldsdebt": Number(ldsdebt),
                "vtb": Number(vtb),
                "yandexmoney": Number(yandexmoney),
                "epayments": Number(epayments),
                "paypal": Number(paypal),
                "raiff": Number(raiff),
                "brokerusd": Number(brokerusd),
                "brokerrub": Number(brokerrub),
                "iisusd": Number(iisusd),
                "iisrub": Number(iisrub)
            })
        };

        fetch(process.env.REACT_APP_PROD_IP + '/home', requestOptions).then((response) => {
            return response.json();
        })
        .then((result) => {
            
        });
    }

    return (
        <div>
            {loading && <Loader />}
            {funds ? (
            <form onSubmit={submitHandler}>
            <table className="table-auto">
                <thead>
                    <tr>
                        <th className="w-56">Name</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="px-2 py-2 border">Tinkoff</td>
                        <td className="px-2 py-2 border"><input value={tinkoff} onChange={e => setTinkoff(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">Sber</td>
                        <td className="px-2 py-2 border"><input value={sber} onChange={e => setSber(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">BTC</td>
                        <td className="px-2 py-2 border"><input value={btc} onChange={e => setBtc(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">Ипотека</td>
                        <td className="px-2 py-2 border"><input value={mortg} onChange={e => setMortg(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">Наличка</td>
                        <td className="px-2 py-2 border"><input value={cash} onChange={e => setCash(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">Мне должны</td>
                        <td className="px-2 py-2 border"><input value={ldsdebt} onChange={e => setLdsdebt(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">VTB</td>
                        <td className="px-2 py-2 border"><input value={vtb} onChange={e => setVtb(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">Yandex Money</td>
                        <td className="px-2 py-2 border"><input value={yandexmoney} onChange={e => setYandexmoney(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">EPayments</td>
                        <td className="px-2 py-2 border"><input value={epayments} onChange={e => setEpayments(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">PayPal</td>
                        <td className="px-2 py-2 border"><input value={paypal} onChange={e => setPaypal(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">raiff</td>
                        <td className="px-2 py-2 border"><input value={raiff} onChange={e => setRaiff(e.target.value)} /></td>
                    </tr>
                </tbody>
            </table>

            <table className="table-auto mt-5">
                <thead>
                    <tr>
                        <th className="w-56">Name</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="px-2 py-2 border">BROKER USD</td>
                        <td className="px-2 py-2 border"><input value={brokerusd} onChange={e => setBrokerusd(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">BROKER RUB</td>
                        <td className="px-2 py-2 border"><input value={brokerrub} onChange={e => setBrokerrub(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">IIS USD</td>
                        <td className="px-2 py-2 border"><input value={iisusd} onChange={e => setIisusd(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className="px-2 py-2 border">IIS RUB</td>
                        <td className="px-2 py-2 border"><input value={iisrub} onChange={e => setIisrub(e.target.value)} /></td>
                    </tr>
                </tbody>
            </table>

            <button type="submit" className="mt-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white px-4 border border-blue-500 hover:border-transparent rounde">SAVE</button>
            </form>
            ) : (
                loading ? null : <p>No DATA</p>
            )}
        </div>
    )
}

export default App