import React, {Component} from 'react';

export default class Chartstable extends Component {
 
    constructor(props){
        super(props);
        this.getHeader = this.getHeader.bind(this);
        this.getRowsData = this.getRowsData.bind(this);
        this.getKeys = this.getKeys.bind(this);

        this.state = {
            direction: '',
            sortingkey: '',
        }
    }

    onSort = (sortKey) => {
        const data = this.props.data;
        console.log(sortKey)

        data.sort((a, b) => {
            if (a[sortKey] < b[sortKey]) {
                return this.state.direction === 'ascending' ? 1 : -1;
            }
            if (a[sortKey] > b[sortKey]) {
                return this.state.direction === 'ascending' ? -1 : 1;
            }
            return 0;
        });

        console.log('dire: ', this.state.direction)

        this.setState({data})
    };

    requestSort = (key) => {
        let direction = 'ascending';
        if (
            this.state.sortingkey === key &&
            this.state.direction === 'ascending'
        ) {
            direction = 'descending';
        }

        this.setState({ sortingkey: key })
        this.setState({ direction: direction })

        this.onSort(key)
    };

    getClassNamesFor = (sortKey) => {
        if (!this.state.sortingkey) {
          return;
        }
        return this.state.sortingkey === sortKey ? this.state.direction : undefined;
    }

    getKeys = function(){
        return Object.keys(this.props.data[0]);
    }

    getHeader = function(){
        var keys = this.getKeys();
        
        return keys.map((key, index)=>{
            return <th key={key}><button type="button" onClick={e => this.requestSort(key)} className={this.getClassNamesFor(key)}>{key.toUpperCase()}</button></th>
        })
    }

    getRowsData = function(){
        var items = this.props.data;
        var keys = this.getKeys();
        
        return items.map((row, index)=>{
            return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>
        })
    }

    render() {
        return (
            <div>
                <table className="sortedtable">
                    <thead>
                        <tr>{this.getHeader()}</tr>
                    </thead>
                    <tbody>
                        {this.getRowsData()}
                    </tbody>
                </table>
            </div>
        );
    }
}


const RenderRow = (props) => {
    
    return props.keys.map((key, index)=>{
        // console.log("key: ", key)
        // console.log("P key: ", props.data[key]);
        // console.log(getRandom())
        //return <td key={props.data[key]}>{props.data[key]}</td>
        return <td key={getRandom()}>{props.data[key]}</td>
    })
}

const getRandom = () => {
    return Math.floor(Math.random() * Math.floor(10000000));
}