import React, { useState, useEffect } from 'react';
import Moment from 'moment'
import Loader from '../Loader'
//import { Link, BrowserRouter as Router, Route } from 'react-router-dom';

import Swal from 'sweetalert2'
import { format } from "date-fns";
import { Paper, TextField } from "@material-ui/core";
import { EditingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { useHistory } from "react-router-dom";

import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';

function App() {
    const history = useHistory();
    const getRowId = row => row.id;

    let [rows, setRows] = useState([])
    const columns = [
        { name: 'name', title: 'Name' },
        { name: 'ticker', title: 'Ticker' },
        { name: 'amount', title: 'Amount' },
        { name: 'purchaseprice', title: 'PurchasePrice' },
        { name: 'datepurchase', title: 'DatePurchase' },
    ];
    
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        const mainkey = localStorage.getItem('mainkey')
        fetch(process.env.REACT_APP_PROD_IP + '/funds/rub/bonds', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            }
        })
        //.then(response => response.json())
        .then(function (response) {
            if (response.status >= 200 && response.status <= 299) {
              return response.json();
              //console.log('1');
            } else {
                //throw Error(response.statusText);
                console.log('2');
                localStorage.clear()
                history.push({
                    pathname: '/login',
                    state: { detail: "sdf" },
                })
                window.location.reload()
                throw Error(response.statusText);
            }
        })
        .then(rows => {
            // setTimeout(() => {
                setRows(rows)
                setLoading(false)
            // }, 1)
        })
    }, [history])

    rows = rows || [];

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////       DELETE     ////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function SweetAlert(item) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                deleteFund(item)
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        });
    }

    function deleteFund (item) {
        const mainkey = localStorage.getItem('mainkey')
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify({ id: item })
        };
    
        fetch(process.env.REACT_APP_PROD_IP + '/funds/rub/' + item, requestOptions).then((response) => {
            return response.json();
            }).then((result) => {
            // do what you want with the response here
        });
      
        //window.location.reload(false);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////       SAVE     /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function addFund(value) {
        console.log("!!! value", value)
        setRows(rows.concat([{
            id: Date.now(),
            name: value[0].name,
            ticker: value[0].ticker,
            amount: value[0].amount,
            purchaseprice: value[0].purchaseprice,
        }]))
        saveAddedFund(value)
    }

    function saveAddedFund(value) {
        const mainkey = localStorage.getItem('mainkey')

        console.log("VALUE", value)
        console.log("VALUE0", value[0])
        console.log("VALUE1", value[1])

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify({ 
                "name": value[0].name,
                "ticker": value[0].ticker,
                "amount": Number(value[0].amount),
                "purchaseprice": Number(value[0].purchaseprice),
                "datepurchase": Moment(value[0].datepurchase).format(),
                "type": "bond"
            })
        };
        console.log("requestOptions", requestOptions)

        fetch(process.env.REACT_APP_PROD_IP + '/funds/rub', requestOptions).then((response) => {
            return response.json();
        }).then((result) => {

        });

        //window.location.reload(false);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////      UPDATE    /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function changeFund(value) {
        editedFund(value)
    }

    function editedFund(value) {
        const mainkey = localStorage.getItem('mainkey')

        console.log("value", value)
        let blablabla

        Object.entries(value).map((item, i) => {
            console.log("i", i)
            console.log("item", item)
            console.log("item[1]", item[1])

            blablabla = item[1]
            return blablabla         
        });

        console.log("blablabla", blablabla)
        
        let standjson = {
             "id": Number(Object.keys(value)[0]),
             "type": "bond"
        }

        console.log("standjson", standjson)

        const combined = { ...blablabla, ...standjson }

        if (combined.amount) {
            combined.amount = Number(combined.amount)
            console.log("combined.anount", combined.amount)
        }

        if (combined.purchaseprice) {
            combined.purchaseprice = Number(combined.purchaseprice)
            console.log("combined.purchaseprice", combined.purchaseprice)
        }

        if (combined.datepurchase) {
            combined.datepurchase = Moment(combined.datepurchase).format('')
            console.log("combined.datepurchase", combined.datepurchase)
        }

        console.log("combined", combined)

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Token': mainkey
            },
            body: JSON.stringify(combined)
        };
        console.log("requestOptions", requestOptions)

        fetch(process.env.REACT_APP_PROD_IP + '/funds/rub/' + standjson.id, requestOptions).then((response) => {
            return response.json();
        }).then((result) => {
            // do what you want with the response here
        });
        
      //window.location.reload(false);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////   COMMIT CHANGES  ////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const commitChanges = ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
          const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
          changedRows = [
            ...added.map((row, index) => ({
              id: startingAddedId + index,
              ...row,
            })),
            ...rows,
          ];
          console.log("ADDED")
          console.log("added", added)
          console.log("changedRows", changedRows)
          addFund(added)
        }
        if (changed) {
          changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
          changeFund(changed)
          console.log("changedRows", changedRows)
          console.log("changed", changed)
          console.log("CHANGED")
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            console.log("rows", rows)
            changedRows = rows.filter(row => !deletedSet.has(row.id));
            console.log("deletedSet", deletedSet)
            console.log("changedRows", changedRows)
            SweetAlert(deleted[0])
        }
        setRows(changedRows);
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////   DATE PICKER  /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // DataTypeProvider for the DatePicker Component
    const LookupFormatter = props => (
        <DataTypeProvider
            editorComponent={params => <LookupEditor {...params} />}
            {...props}
        />
    );

    // DatePicker Component custom
    const LookupEditor = ({ value, onValueChange }) => {
        return (
            <TextField
                id="date"
                type="date"
                value={value ? value : format(new Date(), "yyyy-MM-dd")}
                onChange={e => {
                    onValueChange(e.target.value);
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
        );
    };

    // DatePicker column selection
    const [lookupColumns] = useState(["datepurchase"]);

    //format date
    const [dateColumns] = useState(['datepurchase']);
    const DateFormatter = ({ value }) => Moment(value).format('LL');
    const DateTypeProvider = props => (
        <DataTypeProvider
        formatterComponent={DateFormatter}
        {...props}
        />
    );

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////// FORMAT CURENCY /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const CurrencyFormatter = ({ value }) => (
        <b style={{ color: 'darkblue' }}>
            {value.toLocaleString('en-US', { style: 'currency', currency: 'RUB' })}
        </b>
    );
  
    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    const [currencyColumns] = useState(['purchaseprice']);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////     RENDER     /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
        {loading && <Loader />}

        <Paper>
        <Grid
            rows={rows}
            columns={columns}
            getRowId={getRowId}
        >
        <LookupFormatter for={lookupColumns} />
        <DateTypeProvider for={dateColumns} />
        <CurrencyTypeProvider for={currencyColumns} />
        <EditingState
            onCommitChanges={commitChanges}
        />
        <Table />
        <TableHeaderRow />
        <TableEditRow />
        <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
        />
        </Grid>
        </Paper>

        </div>
    )
}

export default App